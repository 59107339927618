<template>
  <div class="w-100-p flex flex-column align-center justify-center" style="user-select: none">
    <div class="w-500 relative flex flex-column align-center justify-center">
      <img class="w-100-p mt-40" src="@/assets/imgs/common/error.png" >
      <div class="flex justify-center" style="position: absolute; top: 450px;">
        <div style="width: 750px; text-align: center;">
          <p class="flex flex-column justify-center" style="color: #2B72D8; font-weight: bold; font-size: 30px; line-height: 1.5">当前账号未配置菜单，请先配置菜单再登录</p>
          <div class="flex justify-center mt-30" >
            <el-button icon="el-icon-arrow-left" style="background: #2B72D8; border: none; color: #fff;" size="large" @click="$router.push('/login')">返回登录</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .pointer:hover {
    opacity: .9;
  }
</style>
